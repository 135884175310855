
body {
  color: rgb(208, 211, 202);
}

h3 {
  color: rgb(224, 226, 220);
  font-weight: 300;
  font-size: 1.2em;
  margin-bottom: .6em;
  margin-top: .6em;
  text-align: center;
}

hr {
  margin: 10px 0 10px 0;
}

.column {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.row {
  display: flex;
  flex-direction: row;
}

.link-container {
  justify-content: space-between;
  padding: 20px;
}

.main-container{
  padding-top: 2em;
}

.image {
  border: 1px solid black;
  margin: 4px;
  min-width: 150px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  max-width: 25%;
}

.image-mobile {
  border: 1px solid black;
  margin: 4px;
  min-width: 120px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: table-cell;
}

.spacerTop {
  margin-top: 25px;
}

.art-text{
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 25px;
}

.width50{
  min-width: 420px;
  max-width: 420px;
}

.digitalImage{
  height: 240px;
}

.width90{
  width: 90%;
}

.art__container{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(120, 128, 126);
  padding: 10px;
  border-radius: 3px;
  background:rgb(61, 61, 59);
  margin-bottom: 25px;
  display: flex; 
  flex-direction: row;
  min-width: 80vw;
}

.digitalContainer {
  display: flex; 
  flex-direction: row; 
}

.art__container-mobile{
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(120, 128, 126);
  padding: 10px;
  border-radius: 3px;
  background:rgb(61, 61, 59);
  margin-bottom: 2px;
  margin-top: 20px; 
  line-height: 1.5em;
}

.digitalCard-container {
  background-color: rgba(0, 0, 0, 0.705);
}

.titleArea {
  padding: 0 20px;
}
.digitalCard{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(43, 43, 43);
  padding: 3px;
  border-radius: 2px;
  background:rgb(0, 0, 0);
  display: flex; 
  flex-direction: row;
  max-height: 250px;
}

.art__container-mobile{
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(120, 128, 126);
  padding: 10px;
  border-radius: 3px;
  background:rgb(61, 61, 59);
  margin-bottom: 2px;
  margin-top: 20px; 
  line-height: 1.5em;
  
}

.save__container{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(125, 134, 132);
  padding: 10px;
  border-radius: 3px;
  background:rgb(94, 94, 91);
  color:rgb(238, 233, 226);
  flex: 1;
  text-align: center;
  margin-top: 4px;
  cursor: pointer;
}

.purchase{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(125, 134, 132);
  padding: 5px;
  border-radius: 2px;
  background:rgb(94, 94, 91);
  color:rgb(238, 233, 226);
  margin-top: 10px;
  cursor: pointer;
  width: 88px;
  font-size: 1.2em;
}

.purchase:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(145, 189, 179);
  padding: 5px;
  border-radius: 2px;
  background:rgb(92, 92, 88);
  color:rgb(255, 250, 242);
  margin-top: 10px;
  cursor: pointer;
  width: 88px;
  font-size: 1.2em;
}

.art__container img {
  width: 100%;
  height: auto;
  border: 1px solid rgb(0, 0, 0);
}

.art__container-mobile img {
  width: 100%;
  height: auto;
  border: 1px solid rgb(0, 0, 0);
}

.comment_container{
  line-height: 1.5em;
  padding-left: 25px;
}

.comment_container_digital{
  line-height: 1.5em;
  padding-left: 25px;
  max-width: 30vw;
}

.art-com-container{
  display: flex; 
  flex-direction: row; 
}

.art-com-container-mobile{
  display: flex; 
  flex-direction: column; 
}

.mobile-caption{
  width: 100%;
  color: rgb(192, 194, 188);
}

.row-container{
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
}

#com_cont {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 800ms, opacity 800ms;
  padding-left: 6%;
  color: rgb(0, 0, 0);
  width: 30%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(223, 228, 227);
  padding: 10px;
  border-radius: 3px;
  background:rgba(224, 224, 216, 0.829);
  position: fixed;
  width: 31%;
  left: 61%;
  top: 90px;
  margin-right: 9%;
}

#art_cont:hover + #com_cont {
    visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 600ms;
}

  .dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
  }
  
  .image {
    width: 300px;
  }
  
  .popup_b {
    border: solid 1px black !important;
    border-radius: 5px !important;
  }

  .endtitle {
  
    padding-left: 50px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    
  }
  
  img {
    cursor: pointer;
  }

  .modal-container {
    z-index: 5;
    padding: 5px;
    position:relative;
    background-color: rgba(36, 34, 34, 0.425);
    max-height: 90%;
    width: 100%;
  }

  .large-art{
    max-height:90vh;
    max-width:90vw;  
    object-fit: contain;
    border: 2px black solid;
  }

  .small-art{
    max-width:80vw;  
    min-width:80vw;  
    object-fit: contain;
    border: 2px black solid;
  }

  
  .menu-items:hover{
    cursor: pointer;
  }
  
  .border-gradient {
    border-top: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
  }
  
  /* link styles */
  .endtitle a {
    color: #000000;
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
  }
  
  /* Change the color of links on hover */
  .endtitle a:hover {
    color: white;
    text-shadow: 2px 2px #6363632f;
  
  }
  
  /* Add a green background color to the active link */
  .endtitle a.active {
    color: white;
  }
  
   /* Hide the link that should open and close the navbar on small screens */
  .endtitle .icon {
    display: none;
  } 
  
   /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the navbar (.icon) */
  
   .title {
    color: rgb(255, 255, 255);
    font-size: 1.3em;
  }

  .cardText {
    color: white;
    font-weight:500;
  }

  .title-mobile {
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }

  .red-dot-notMobile {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    top: -24px;
    left: 10px;
    border: 1px solid black;
    z-index: 20;
  }


  .red-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    top: 20px;
    left: 10px;
    border: 1px solid black;
  }

  @media screen and (max-width: 800px) {
    .NotMobile {
      display: none;
    }
  }

  @media screen and (min-width: 801px) {
    .Mobile {
      display: none;
    }
  }

  .buy-container {
    width: 80vw;
    min-height: 80vh;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(125, 134, 132);
  padding: 25px;
  border-radius: 2px;
  background:rgb(94, 94, 91);
  color:rgb(238, 233, 226);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }