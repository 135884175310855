@media screen and (max-width: 800px) {
  .contactContainer {
    width: 90%;
    margin: 35px auto 25px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    border: 1px solid rgb(120, 128, 126);
    border-radius: 3px;
    background: rgb(61, 61, 59);
  }

  .textContainer {
    display: none;
  }

  .textContainer-Mobile {
    margin: 35px auto 25px auto;
  }

  .contact-item {
    margin: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.275);
    padding: 10px 20px 10px 10px;
    line-height: 1.2em;
  }

  .contact-item-rows {
    margin: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.275);
    padding: 10px 20px 10px 10px;
    line-height: 1.9em;
  }
}

@media screen and (min-width: 801px) {
  .contactContainer {
    width: 70%;
    margin: 35px auto 25px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    border: 1px solid rgb(120, 128, 126);
    border-radius: 3px;
    background: rgb(61, 61, 59);
  }
  .textContainer-Mobile {
    display: none;
  }
}

.aboutContainer {
  margin: auto;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(120, 128, 126);
  border-radius: 3px;
  background: rgb(61, 61, 59);
  margin-bottom: 125px;
}

.aboutContainer-real {
  margin: 35px auto;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  border: 1px solid rgb(120, 128, 126);
  border-radius: 3px;
  background: rgb(61, 61, 59);
}

p,
h2 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.column {
  display: flex;
  flex-direction: column;
}

h2 {
  padding-top: 1.5em;
  color: white;
}

li {
  text-indent: 12px;
  list-style-position: inside;
  padding-bottom: 8px;
}

ul ul {
  margin-left: 15px;
}

.portrait {
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px black solid;
}

.border {
  width: 45px;
  background-color: rgb(224, 224, 193);
}

.aboutFooter {
  width: 100%;
  display: block;
  border-top: 1px solid rgb(120, 128, 126);
}

@media only screen and (max-width: 800px) {
  .aboutFooter {
    height: 98px;
  }
}

@media only screen and (min-width: 801px) {
  .aboutFooter {
    height: 180px;
  }
}

.textContainer {
  padding: 5%;
  padding-top: 15px;
}

.textContainer-about {
  padding: 5%;
}

.aboutLink {
  color: rgb(210, 218, 241);
  text-decoration: underline;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-right: 40px;
}

a {
  color: rgb(221, 224, 255);
  text-decoration: none;
}

td {
  padding: 3px;
}

.telepathy {
  color: rgb(221, 224, 255);
  text-decoration: none;
}

.centerStuff {
  border: 1px solid rgb(120, 128, 126);
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
}
