.backgrounds-container {
    position: absolute;
    top: 78px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.backgroundCard{
    width: 200px;
    max-height: 200px;
}

.contain {
    object-fit: cover;
    width: 200px;
    max-height: 200px;
}

.backgroundRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.background-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    border: 1px solid rgb(120, 128, 126);
    padding: 4px;
    border-radius: 3px;
    background:rgb(61, 61, 59);
    margin:13px;
    line-height: 1.5em;
}

.inner {
    max-width: 550px;
    padding: 15px;
}