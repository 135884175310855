
 

.input-form {
}

.searchButton-sell {
    border: 1px solid black;
    width: 140px;
    margin: 6px;
    background: rgb(250, 242, 169);
    font-size: 16px;
    padding: 4px;
}

.green-border{
    -moz-box-shadow:    inset 0 0 5px #315e2b;
    -webkit-box-shadow: inset 0 0 5px #315e2b;
    box-shadow:         inset 0 0 5px #a52424;
    border: 1px solid rgb(0, 0, 0) !important;
}

.searchButton-sell:hover{
    background-color: rgb(255, 253, 230);
}


.input-item-checkbox{
    border: 1px solid black;
}

.sell-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sell-row-textbox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.sell-column{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.sell-condition{
    width: 100px;
    padding: 0px 05px 0px 15px;
}

.sell-checkbox{
    width: 100%;
}

input[type="checkbox"] {
    margin:-15px;
 }


 .fill-width {
    flex: 1;
  }

  .sell-right{
    background: rgb(206, 206, 206);
    background: linear-gradient(119deg, rgb(235, 235, 235), rgb(206, 206, 206));
    padding: 20px;
    padding-right: 30px;
    flex: .5;
    border: 2px rgb(236, 217, 47) solid;

  }

  .sell-right-locate {
    background: rgb(206, 206, 206);
    background: linear-gradient(119deg, rgb(235, 235, 235), rgb(206, 206, 206));
    padding: 20px;
    padding-right: 30px;
    flex: .5;
    border: 2px rgb(20, 46, 192) solid;
  }

  .sell-right-buy {
    background: rgb(206, 206, 206);
    background: linear-gradient(119deg, rgb(235, 235, 235), rgb(206, 206, 206));
    padding: 20px;
    padding-right: 30px;
    flex: .5;
    border: 2px rgb(1, 150, 8) solid;
  }

  .sell-left{
    padding: 10px;
    flex: .5;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(146, 141, 132);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(146, 141, 132);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(146, 141, 132);
  }

  textarea  
{  
   padding: 10px;
   font-size: 16px;  
}

.form-failed {
    z-index: 10;
    width: 50%;
    background-color: rgb(108, 156, 110);
    border: 1px solid rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin-left: 7px;
}

.quote-complete{
    z-index: 10;
    margin-top: 2px;
    background-color: rgb(210, 252, 212);
    border: 1px solid rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin-left: 7px;
}

.submit-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 1.0em;
  }

  .purchase{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    border: 1px solid rgb(125, 134, 132);
    padding: 5px;
    border-radius: 2px;
    background:rgb(94, 94, 91);
    color:rgb(238, 233, 226);
    margin-top: 10px;
    cursor: pointer;
    width: 190px;
  }

  .purchase:hover{
    border: 1px solid rgb(145, 189, 179);
    width: 190px;
  }

  .bigtext {
      color: rgb(233, 255, 233);
      font-size: 1.2em;
  }