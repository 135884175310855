.body-card-container {
    width: 90%;
    margin:auto;
}

.body-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin: 0px 15px;
}

.mobile-class {
    width: 90%;
    margin: auto;
}

.white {
    font-size: 1;
    color: rgb(221, 214, 185);
}

.art-title {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.art-title-notMobile {
    padding: 15px 0 20px 20px;
    align-items: center;
}


@media screen and (max-width: 800px) {
    .NotMobile {
      display: none;
    }
  }

  @media screen and (min-width: 801px) {
    .Mobile {
      display: none;
    }
  }

