* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
  }
  
  .App {
    font-family: 'Ubuntu', sans-serif;
  }

  body {
    background-color: #232323;
    overflow-y: scroll;
  }

