:root {
    --lines: #000000;
  }

html, body {
    margin:0px;
    height:100%;
    margin-left: calc(100vw - 100%);
  }
  
.homeContainer {
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
}

.leftA {
  text-align: left;
  padding: 10px;
}

.r {
    border-right: 5px solid var(--lines);
}

.rb {
    border-right: 5px solid var(--lines);
    border-bottom: 5px solid var(--lines);
}

.b {
    border-bottom: 5px solid var(--lines);
}

.b1 {
    border: 1px solid rgb(145, 127, 127);
}

.trb {
    border-right: 5px solid var(--lines);
    border-bottom: 5px solid var(--lines);
    border-top: 5px solid var(--lines);
}


.trb {
    border-bottom: 5px solid var(--lines);
    border-top: 5px solid var(--lines);
}

.rblb {
    border-bottom: 5px solid var(--lines);
    border-top: 5px solid var(--lines);
    border-left: 5px solid var(--lines);
    border-right: 5px solid var(--lines);
}

.big-table {
    border-collapse: collapse;
    height:100%;
    width: 100%;
    z-index: -5;
}

td {
    z-index: -5;
}

.topLeft {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.redBox {
    margin-left: -2px;
    margin-top: -2px;
    width: 16px;
    height: 16px;
    background-color: rgb(192, 0, 0);
    border-right: 2px black solid;
    border-bottom: 2px black solid;
}



.click {
    width: 100%;
    height: 100%;
}

.brown {
    opacity: 20%;
}

.brown:hover {
    opacity: 50%;
}

.sky {
    opacity: 20%;
}

.red {
    opacity: 20%;
}

.sky:hover {
    opacity: 50%;
}

.red:hover {
    opacity: 50%;
}

td {
    text-align: center;
  }



  .color-animate-1 {
    animation-name: ca1;
    animation-duration: 8s;
    animation-iteration-count:infinite;
  }

  .color-animate-2 {
    animation-name: ca1;
    animation-duration: 11s;
    animation-iteration-count:infinite;

  }

  .color-animate-3 {
    animation-name: ca1;
    animation-duration: 15s;
    animation-iteration-count:infinite;

  }

  .color-animate-4 {
    animation-name: ca1;
    animation-duration: 10s;
    animation-iteration-count:infinite;

  }

  .color-animate-5 {
    animation-name: ca1;
    animation-duration: 12s;
    animation-iteration-count:infinite;

  }

  .color-animate-5 {
    animation-name: ca1;
    animation-duration: 16s;
    animation-iteration-count:infinite;

  }

  .color-animate-6 {
    animation-name: ca1;
    animation-duration: 14s;
    animation-iteration-count:infinite;

  }

  .color-animate-7 {
    animation-name: ca2;
    animation-duration: 7s;


  }

  @keyframes ca1 {
    0% {color: #232323;}
    20% {color: #232323;}
    50%  {color: rgb(110, 108, 97);}
    80% {color: #232323;}
    100% {color: #232323;}
    
  }

  @keyframes ca2 {
    0% {color: #232323;}
    50%  {color: rgb(110, 108, 97);}
    100% {color: #e2e0e0;}
    
  }

 

  .brands-list {
    color: #202020;
    color: white;
 }

 h3 {
     font-size: 1em;
     color: rgb(221, 221, 208);
 }

 @media screen and (max-width: 800px) {
    .NotMobile {
      display: none;
    }
  }

  @media screen and (min-width: 801px) {
    .Mobile {
      display: none;
    }
  }

  .mainContent{
    border-radius: 4px;
    margin-bottom: 25px;
    width: 76%;           
    position: absolute;
    left: 12%;
    right: 12%;
    z-index: 10;
    top: 105px;
  }

  .mainImage {
    width: 100%;
  }

  .bar {
      padding: 15px;
  }

  .clickOpen {
      background-color: rgb(22, 21, 21);
      color: rgb(231, 231, 226);
      padding: 15px;
      cursor: pointer;
      font-size: 1.2em;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  .clickOpen2 {
    background-color: rgb(22, 21, 21);
    color: rgb(231, 231, 226);
    padding: 15px;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


  .news {
      display: grid;
      grid-template-columns: 15px auto 15px;
      grid-template-rows: 20px auto 15px;
      grid-template-areas: 
        "border border border"
        "border itemNoBorder border"
        "border border border";
      color: rgb(208, 211, 202);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    
        
  }

  .mainLink {
      color: rgb(152, 152, 202);
      text-decoration: underline;
  }

  .mainLink:hover {
    color: rgb(163, 113, 255);
  }

  .Twhite {
      color: white;
      padding: 10px;
  }

.border2{
    background-color: rgb(22, 21, 21);
}

  .itemNoBorder {
    padding: 20px;
    background-color: rgba(15, 68, 75, 0.096);
    backdrop-filter: blur(9px);
  }

  .itemNoBorder2 {
    display: grid;
    grid-template-columns: 1fr 178px;
    gap: 10px;
    padding: 20px;
    background-color: rgba(15, 68, 75, 0.096);
    backdrop-filter: blur(9px);
    line-height: 1.5em;
  }

 .bannerImage {
     width: 100%;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
    border-radius: 5px;
    cursor: default;
 }

 .spacer {
   height: 25px;
 }

 .footer {
  color: #454545;
 }