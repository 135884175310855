.link {
  color: #e8e8e8;
  text-decoration: none;
  margin-left: 21px;
  margin-top: 20px;
  z-index: 5;
}

.link-small {
  margin: 12px 14px 0 15px;
}

.link-small:hover {
  opacity: unset;
}

.link:hover {
  text-decoration: underline;
  color: #b4b4b4;
}

.navbar {
  padding: 0 10px;
  min-height: 9vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 5;
}

.navbar-images {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 15px;
  z-index: 5;
}

.nav-items {
  margin: 5px;
  display: flex;
  flex-direction: row;
}

.nav-items-small {
  margin: 5px;
  display: flex;
  flex-direction: row;
  z-index: 5;
}

.bigLetter {
  font-size: 1.5em;
  color: #ffffff;
}

.headerLink {
  color: #e8e8e8;
  text-decoration: none;
  margin-left: 21px;
  margin-top: 3px;
  z-index: 5;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .NotMobile {
    display: none;
  }
}

@media screen and (min-width: 801px) {
  .Mobile {
    display: none;
  }
}

